import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-self: flex-end;
  height: 60px;
  width: 12vw;
  margin: 0px 20px 50px 0px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 20px;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    min-width: 6vw;
    width: 6vw;
    margin: 0px 0px 50px 0px;

    /* hide the logos */
    > a {
      display: none;
    }
  }

  > a {
    transform: rotate(-90deg);
    text-decoration: none;
    color: black;
  }
`

const SocialMedia = () => {
  return (
    <Container>
      <a href="https://www.instagram.com/solo__club/">
        <span className="icon-instagram" />
      </a>
      <a href="https://www.facebook.com/soloclubapp">
        <span className="icon-facebook" />
      </a>
    </Container>
  )
}

export default SocialMedia
