/**
 * @file Footer
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  position: ${props => (props.positionFixed ? 'fixed' : 'unset')};
  bottom: ${props => (props.positionFixed ? '20px' : 'unset')};
  margin-top: ${props => (props.noTopMargin ? 0 : '60px')};
  margin-bottom: 20px;

  /* hide the footer for now, in desktop sizes */
  @media only screen and (min-width: 768px) {
    display: ${props => (props.hideInLargeScreen ? 'none' : 'flex')};
  }
`

const SocialMediaContainer = styled.div`
  padding-bottom: 10px;
  display: flex;

  a {
    height: 16px;
    width: 16px;
    margin: 0 5px;
    text-decoration: none;
    color: inherit;
  }
`

const Footer = ({ hideInLargeScreen, noTopMargin, positionFixed }) => {
  return (
    <Container
      positionFixed={positionFixed}
      hideInLargeScreen={hideInLargeScreen}
      noTopMargin={noTopMargin}
    >
      <SocialMediaContainer>
        <a href="https://www.facebook.com/soloclubapp">
          <span className="icon-facebook" />
        </a>
        <a href="https://www.instagram.com/solo__club/">
          <span className="icon-instagram" />
        </a>
      </SocialMediaContainer>
      <h6>Solo © 2020</h6>
    </Container>
  )
}

Footer.propTypes = {
  positionFixed: PropTypes.bool,
  hideInLargeScreen: PropTypes.bool,
  noTopMargin: PropTypes.bool,
}

Footer.defaultProps = {
  positionFixed: false,
  hideInLargeScreen: false,
  noTopMargin: false,
}

export default Footer
