import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { JOIN_WAITLIST_URL } from '../constants'

import Layout from '../components/layout'
import SEO from '../components/seo'
import titleImage from '../images/home/solo_dinners.jpeg'
import { PureInput } from '../components/input'
import Button from '../components/button'
import Title from '../components/home/title'
import SocialMedia from '../components/home/socialMedia'
import Footer from '../components/footer'

// TODO: add landscape version
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    flex-direction: column;
  }
`

const Content = styled.div`
  padding-top: 31vh;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-right: 6vw;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    padding-top: 23vh;
  }
`

const SubContentContainer = styled.div`
  display: flex;
  height: 100%;
`

const SubContent = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 60px;
  padding-top: 5px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    border-left: unset;
    padding-left: 0px;
  }
`

const TitleImage = styled.img`
  height: 90vh;
  position: relative;
  right: 0px;
  top: 10vh;
  border-radius: 10px 0 0 0;
  object-fit: cover;
  object-position: center;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    height: 75vh;
    right: 0px;
    margin: 30px 20px 125px 20px;
    border-radius: 10px;
  }
`

const EarlyAccessContainer = styled.div`
  margin-top: 210px;
  padding-bottom: 80px;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    margin-top: 140px;
    padding-bottom: 40px;
  }
`

const Form = styled.form`
  display: flex;
  align-items: flex-end;

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    flex-wrap: wrap;

    > input {
      width: 100%;
    }

    > button {
      width: 100%;
      margin-top: 10px;
      margin-left: 0 !important;
    }
  }
`

const Error = styled.p`
  color: #dd3f4e;
`

const IndexPage = () => {
  const [responseSaved, setResponseSaved] = useState(false)
  const [responseError, setResponseError] = useState('')
  const { register, handleSubmit } = useForm()

  const onSubmit = async values => {
    try {
      const { success, error } = await fetch(JOIN_WAITLIST_URL, {
        method: 'POST',
        body: JSON.stringify({ phoneNumber: values.phoneNumber }),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => response.json())

      if (success) setResponseSaved(true)
      setResponseError(error)
    } catch (err) {
      setResponseError('Internal Server Error')
    }
  }

  return (
    <Layout isHome>
      <SEO title="Solo" />
      <Container>
        <Content>
          <Title />
          <SubContentContainer>
            <SocialMedia />
            <SubContent>
              <h4 style={{ opacity: 0.5 }}>
                Connecting you to other people in this solo world, one call at a
                time
              </h4>
              <EarlyAccessContainer>
                <h4 style={{ marginBottom: 10 }}>Sign up for the waitlist</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <PureInput
                    name="phoneNumber"
                    style={{ backgroundColor: '#F7F7FA' }}
                    ref={register({ required: true })}
                    type="tel"
                    placeholder="+1 888 888 8888"
                  />
                  <Button
                    small
                    style={{ marginLeft: 20, minWidth: 145 }}
                    disabled={responseSaved}
                  >
                    {responseSaved ? 'Thank You' : "Let's Go"}
                  </Button>
                </Form>
                {responseError && <Error>{responseError}</Error>}
              </EarlyAccessContainer>
            </SubContent>
          </SubContentContainer>
        </Content>
        <TitleImage src={titleImage} />
        <Footer hideInLargeScreen noTopMargin />
      </Container>
    </Layout>
  )
}

export default IndexPage
