/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { Link } from 'gatsby'
import logo from '../images/logo.png'
import './layout.css'
import { lightTheme } from '../constants'

const Container = styled.div`
  height: ${props => (props.isHome ? '100vh' : '100%')};
  width: 100%;

  @media only screen and (max-width: 768px) {
    height: 100%;
  }
`

const Logo = styled.img`
  height: 50px;
  object-fit: contain;
`

const Header = styled.div`
  z-index: 101;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px 0px 30px;
  background-color: #fff;

  @media only screen and (max-width: 768px) {
    padding: 20px 20px 0px 20px;
  }

  a {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`

// TODO: currently isHome is used to set the 100vh, change it when it gets scrollable
const Layout = ({ children, hideHeader, isHome }) => {
  const currentUser = useSelector(state => state.auth.user)

  return (
    <ThemeProvider theme={lightTheme}>
      <Container isHome={isHome}>
        {!hideHeader && (
          <Header>
            <a href="/">
              <Logo src={logo} />
            </a>
            {currentUser?.name && (
              <Link to={`/app/user/${currentUser.id}`}>
                <h4 style={{ paddingBottom: 5 }}>Hello, {currentUser.name}</h4>
              </Link>
            )}
          </Header>
        )}
        {children}
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideHeader: PropTypes.bool,
  isHome: PropTypes.bool,
}

Layout.defaultProps = {
  hideHeader: false,
  isHome: false,
}

export default Layout
