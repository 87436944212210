import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Typed from 'typed.js'

const TitleText = styled.h1`
  padding-left: 6vw;
  padding-bottom: 20px;
`

const IsServedText = styled.span`
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    ::before {
      content: '\\a';
      white-space: pre;
    }
  }
`

const options = {
  strings: ['Friend', 'Person', 'Community'],
  typeSpeed: 60,
  backSpeed: 60,
  backDelay: 2000,
  loop: true,
}

const Title = () => {
  const textRef = useRef(null)

  useEffect(() => {
    const typed = new Typed(textRef.current, options)

    return () => {
      typed.destroy()
    }
  }, [])

  return (
    <TitleText>
      <IsServedText>Meet a new </IsServedText>
      <span ref={textRef} />
    </TitleText>
  )
}

export default Title
